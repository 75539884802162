export default class {
    constructor() {
			this.modal = '.js-modal';
			this.modalItem = '.js-modal-item';
			this.modalTrigger = '.js-modal-trigger';
			this.closeBtn = '.js-modal-close';

			this.init();
			this.closeAction();

		}

	init() {
		const trigger =  Array.prototype.slice.call(document.querySelectorAll(this.modalTrigger))
		const modal = document.querySelector(this.modal);
		const modalItem = Array.prototype.slice.call(document.querySelectorAll(this.modalItem));

		for (let index = 0; index < trigger.length; index++) {
				trigger.forEach((element,index) => {
					element.addEventListener('click', ()=>{
						modal.classList.add('is-active');
						modalItem[index].classList.add('is-active')
				});
			});
		}
	}

	closeAction() {
		const modal = document.querySelector(this.modal);
		const modalItem = Array.prototype.slice.call(document.querySelectorAll(this.modalItem));
		const closeBtn = Array.prototype.slice.call(document.querySelectorAll(this.closeBtn));
		for (let index = 0; index < closeBtn.length; index++) {
			closeBtn[index].addEventListener('click', () => {
				modal.classList.remove('is-active');
				for (let index = 0; index < modalItem.length; index++) {
					modalItem[index].classList.remove('is-active');
				}
			});
		}
	}
}