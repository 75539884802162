import Swiper from'swiper/bundle';
import 'swiper/css/bundle';

export default class {
    constructor() {
        this.hero = '.js-hero';

        this.init();
    }
    init() {
        const hero = document.querySelector(this.hero);
        if (hero) {
            const swiper = new Swiper(hero, {
                speed: 1000,
                loop: true,
                effect: 'fade',
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
            });
        }
    }
}