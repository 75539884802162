import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class {
    constructor() {
        this.group = '.js-group';

        this.init();
    }
    init() {
        const groupSec = document.querySelector(this.group);
        if (groupSec) {
					gsap.to('.js-group-img', {
						scrollTrigger: {
							trigger: '.js-group-img',
							startTrigger: groupSec,
							endTrigger: groupSec,
							start: 'top top',
							end: 'bottom bottom',
							pin: true,
						}
					});
					gsap.to('.js-scroll', {
						scrollTrigger: {
							trigger: '.js-scroll',
							startTrigger: groupSec,
							endTrigger: groupSec,
							start: 'top top',
							end: 'bottom bottom',
							pin: true,
						}
					});
					gsap.set('.js-scroll-line', {
						height: 0,
					})
					gsap.to('.js-scroll-line', {
						height: '100%',
						scrollTrigger: {
							trigger: '.js-scroll-line',
							startTrigger: groupSec,
							endTrigger: groupSec,
							start: 'top center',
							end: 'bottom bottom',
							scrub: true,
						}
					});
					gsap.to( '.js-group-sec01', {
						scrollTrigger: {
							trigger: '.js-group-sec01',
							start: 'top center',
							end: 'bottom center',
							toggleClass: { targets: ".js-group-img, .js-scroll", className: "is-group01" },
						}
					});
					gsap.to( '.js-group-sec02', {
						scrollTrigger: {
							trigger: '.js-group-sec02',
							start: 'top center',
							end: 'bottom center',
							toggleClass: { targets: ".js-group-img, .js-scroll", className: "is-group02" },
						}
					});
					gsap.to( '.js-group-sec03', {
						scrollTrigger: {
							trigger: '.js-group-sec03',
							start: 'top center',
							end: 'bottom center',
							toggleClass: { targets: ".js-group-img, .js-scroll", className: "is-group03" },
						}
					});
        }
    }
}