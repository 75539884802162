export default class {
	constructor() {
			this.telInput = document.querySelector('.js-tel-input');
			this.dateSelectBox = document.querySelector('.js-archive-date');
			this.init();
			this.dateSelect();
	}

	init() {
		const zipInput = document.querySelector('[name="zip"]')
		if (zipInput) {
				zipInput.addEventListener('keydown', () => {
						AjaxZip3.zip2addr('zip', '', 'add01', 'add02');
				});
		}
		const telInput = this.telInput;
		if (telInput) {
				telInput.setAttribute('inputmode','tel');
		}
	}

	dateSelect() {
		const archiveDate = this.dateSelectBox;
		if (archiveDate) {
			console.log('ok');
			archiveDate.addEventListener('change', (e) => {
				location.href = e.target.value;
			});
		}
	}
}